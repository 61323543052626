document.addEventListener("DOMContentLoaded", () => {

  if (document.querySelector('.about-slider')) {
    const aboutSlider = new Swiper(".about-slider", {
      slidesPerView: 4,
      spaceBetween: 12,
      breakpoints: {
        320: {
          slidesPerView: 2,
        },
        640: {
          slidesPerView: 2,
          spaceBetween: 18,
        },
        768: {
          slidesPerView: 3,
        },
        1320: {
          slidesPerView: 4,
        },
      },
      speed: 2000,
      autoplay: {
        delay: 5000,
      },
      pagination: {
        el: ".swiper-pagination",
        clickable: true,
      },
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
    });
  }

  if (document.querySelector('.excellence-slider')) {

    const excellenceSlider = new Swiper(".excellence-slider", {
      loop: true,
      grabCursor: true,
      slidesPerView: 1,
      spaceBetween: 44,
      speed: 1800,
      autoplay: {
        delay: 5000,
      },
      effect: 'fade',
      pagination: {
        el: ".swiper-pagination",
        clickable: true,
      },

    });
  }

  if (document.querySelector('.recall-slider')) {

    const recallSlider = new Swiper(".recall-slider", {
      // loop: true,
      grabCursor: true,
      slidesPerView: 1,
      spaceBetween: 44,
      speed: 1500,
      autoHeight: true,
      navigation: {
        nextEl: ".recall-button-next",
        prevEl: ".recall-button-prev",
      },
    });
  }
});
